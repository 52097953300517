import React from "react";

const DashboardFooter = () => {
  return (
    <footer className="auth-footer">
      <div className="container">
        <div className="footer-options">
          <p>© 2024 Amberwood Real Estate Inc. All Rights Reserved.</p>

          {/* LINKS */}
          {/* <div className="footer-links">
            <a
              href="https://amberwoodre.com/privacy-policy/"
              target="_blank"
              className="link-item"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="https://amberwoodre.com/mls-disclaimer/"
              target="_blank"
              className="link-item"
              rel="noreferrer"
            >
              MLS Disclaimer
            </a>
            <a
              href="https://amberwoodre.com/fair-housing-statement/"
              target="_blank"
              className="link-item"
              rel="noreferrer"
            >
              Fair Housing
            </a>
            <a
              href="https://amberwoodre.com/accessibility/"
              target="_blank"
              className="link-item"
              rel="noreferrer"
            >
              Accessibility
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default DashboardFooter;
