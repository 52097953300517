/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Dropdown,
  Badge,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
// import TimePicker from "rc-time-picker";
import TimePicker from "react-time-picker";
import Select from "react-select";
import moment, { now } from "moment";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import Skeleton from "react-loading-skeleton";
import ShowMoreText from "react-show-more-text";

/* ICON IMPORTS */
import ActionIcon from "@iconscout/react-unicons/icons/uil-ellipsis-v";
import UilTrash from "@iconscout/react-unicons/icons/uil-trash";

/* REDUX STATES */
import actions from "../../../redux/actions/userAction";
import {
  addEvent,
  deleteEvent,
  editEvent,
  getEvent,
} from "../../../service/api";

/* STYLES */
import "rc-time-picker/assets/index.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  startDate: yup.string().required("Start Date is required."),
  endDate: yup.string().required("End Date is required."),
  startTime: yup.string().required("Start Time is required."),
  endTime: yup.string().required("End Time is required."),
  type: yup.string().required("Type is required"),
  discription: yup.string().required("Description is required."),
});

/* EVENT TYPE OPTIONS */
const typeOption = [
  { label: "ONLINE", value: "ONLINE" },
  { label: "IN-PERSON", value: "OFFLINE" },
];

const Events = () => {
  /* STATES */
  const [addEventModal, setAddEventModal] = useState(false);
  const [isVirtualChecked, setIsVirtualChecked] = useState(false);
  const [isPersonChecked, setIsPersonChecked] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setTime] = useState(null);
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [totalEvent, setTotalEvent] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [eventLoading, setEventLoading] = useState(true);

  const dispatch = useDispatch();

  /* GET EVENTS [API] */
  const getEventData = () => {
    setEventLoading(true);
    getEvent()
      .then((res) => {
        if (res.status) {
          setTotalEvent(res.data.totalDocs);
          setData(res.data);
          dispatch(actions.setEvent(res.data.docs));
        }
      })
      .finally((res) => {
        setEventLoading(false);
      });
  };

  /* EDIT EVENTS [API] */
  const handleEdit = (data) => {
    console.log(data);
    formik.setFieldValue("name", data?.name);
    formik.setFieldValue("discription", data?.discription);
    formik.setFieldValue("startDate", new Date(data?.startDate));
    formik.setFieldValue("endDate", new Date(data?.endDate));
    formik.setFieldValue("startTime", data?.startTime);
    formik.setFieldValue("endTime", data?.endTime);
    formik.setFieldValue("type", data?.type);
    formik.setFieldValue("image", file);
    formik.setFieldValue("addressLine1", data?.address?.addressLine1);
    formik.setFieldValue("country", data?.address?.country);
    formik.setFieldValue("state", data?.address?.state);
    formik.setFieldValue("city", data?.address?.city);
    formik.setFieldValue("zipCode", data?.address?.zipCode);
    formik.setFieldValue("link", data.link);
    formik.setFieldValue("_id", data._id);
    setPreviewUrl(data?.image);
    setEdit(true);
    setAddEventModal(true);
  };

  /* DELETE EVENTS [API] */
  const handleDelete = (id) => {
    setLoading(true);
    deleteEvent(id)
      .then((res) => {
        if (res?.status) {
          getEventData();
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  /* FORM VALUES HANDLING */
  const formik = useFormik({
    initialValues: {
      name: "",
      startDate: "",
      endDate: "",
      discription: "",
      startTime: "",
      endTime: "",
      type: "ONLINE",
      image: "",
      addressLine1: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      link: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values: ", values);
      let formData = new FormData();
      formData.append("name", values?.name);
      formData.append("discription", values?.discription);
      formData.append(
        "startDate",
        moment(new Date(values?.startDate)).format("MM/DD/YYYY")
      );
      formData.append(
        "endDate",
        moment(new Date(values?.endDate)).format("MM/DD/YYYY")
      );
      formData.append("startTime", values?.startTime);
      formData.append("endTime", values?.endTime);
      formData.append("type", values?.type);
      if (formik?.values?.type === "OFFLINE") {
        formData.append("country", values?.country);
        formData.append("city", values?.city);
        formData.append("zipCode", values?.zipCode);
        formData.append("addressLine1", values?.addressLine1);
        formData.append("state", values?.state);
      } else {
        formData.append("link", values?.link);
      }
      if (file) formData.append("image", file);

      console.log(
        "startDate",
        moment(new Date(values?.startDate)).format("MM/DD/YYYY")
      );
      console.log(
        "endDate",
        moment(new Date(values?.endDate)).format("MM/DD/YYYY")
      );
      console.log("startTime", moment(values?.startTime).format("HH:MM"));
      console.log("endTime", moment(values?.endTime).format("HH:MM"));

      setLoading(true);
      if (!edit) {
        addEvent(formData)
          .then((res) => {
            if (res?.status) {
              toast.success(res?.message);
              setAddEventModal(false);
              getEventData();
              setFile(null);
              setPreviewUrl(null);
              formik.resetForm();
            } else {
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          })
          .finally((res) => {
            setLoading(false);
          });
      } else {
        editEvent(values?._id, formData)
          .then((res) => {
            if (res?.status) {
              toast.success(res?.message);
              setAddEventModal(false);
              setEdit(false);
              getEventData();
              setFile(null);
              setPreviewUrl(null);
              formik.resetForm();
            } else {
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          })
          .finally((res) => {
            setLoading(false);
          });
      }
    },
  });

  /* IMAGE CHANGE HANDLING */
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    formik.handleChange(e);
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
    } else {
      setPreviewUrl(null);
    }

    e.target.value = "";
  };

  /* DELETE EVENT HANDLING */
  const handleDeleteClick = () => {
    setFile(null);
    setPreviewUrl(null);
  };

  /* USE EFFECT */
  useEffect(() => {
    getEventData();
  }, []);

  return (
    <section className="activity">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      {/* PAGE CONTENT */}
      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Events List</h1>
              <p style={{ fontSize: "15px" }}>
                Create and manage the events to be held with Amberwood Real
                Estate
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-flex justify-end">
                <Button
                  className="import-btn"
                  onClick={() => setAddEventModal(!addEventModal)}
                >
                  Add Event
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        {/* ACTIVITIES LIST */}
        <Card className="activities-list">
          <Card.Body className="activities-list-body">
            <Row className="gy-4">
              {eventLoading ? (
                <>
                  <Col lg="3" sm="12">
                    <Card className="activity-card">
                      <Card.Body className="activity-cardbody">
                        <Skeleton className="card-loading" />
                      </Card.Body>
                      <Card.Footer className="ac-footer">
                        <Skeleton className="card-loading" />
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col lg="3" sm="12">
                    <Card className="activity-card">
                      <Card.Body className="activity-cardbody">
                        <Skeleton className="card-loading" />
                      </Card.Body>
                      <Card.Footer className="ac-footer">
                        <Skeleton count={1} className="card-loading" />
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col lg="3" sm="12">
                    <Card className="activity-card">
                      <Card.Body className="activity-cardbody">
                        <Skeleton className="card-loading" />
                      </Card.Body>
                      <Card.Footer className="ac-footer">
                        <Skeleton count={1} className="card-loading" />
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col lg="3" sm="12">
                    <Card className="activity-card">
                      <Card.Body className="activity-cardbody">
                        <Skeleton className="card-loading" />
                      </Card.Body>
                      <Card.Footer className="ac-footer">
                        <Skeleton count={1} className="card-loading" />
                      </Card.Footer>
                    </Card>
                  </Col>
                </>
              ) : (
                data
                  .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
                  .map((activity, _id) => {
                    return (
                      <Col lg="3" sm="12" key={_id}>
                        <Card className="activity-card">
                          <Card.Body className="activity-cardbody">
                            <div className="d-flex align-items-center justify-content-between">
                              <div
                                className={`custom-badge ${
                                  activity.type === "ONLINE"
                                    ? "online"
                                    : "offline"
                                }`}
                              >
                                <p>
                                  {activity.type === "ONLINE"
                                    ? "ONLINE"
                                    : "IN-PERSON"}
                                </p>
                              </div>
                              <Link to={`/rsvp-list/${activity._id}`}>
                                <Button className="rsvp-list-button">
                                  RSVP List
                                </Button>
                              </Link>
                            </div>
                            <h5>{activity.name}</h5>
                            <ShowMoreText
                              lines={3}
                              more="Show more"
                              less="Show less"
                              className="show-more-content-css"
                            >
                              {activity.discription}
                            </ShowMoreText>
                            {activity.type === "ONLINE" ? (
                              <>
                                <div className="meeting-link">
                                  <p className="meeting-link-heading">
                                    Meeting link:
                                  </p>
                                  <Link to={activity.link}>
                                    {activity.link}
                                  </Link>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="meeting-link">
                                  <p className="meeting-link-heading">
                                    Address
                                  </p>
                                  <p className="address">
                                    {activity.address.addressLine1}{" "}
                                    {activity.address.city}{" "}
                                    {activity.address.country}
                                  </p>
                                </div>
                              </>
                            )}
                          </Card.Body>
                          <Card.Footer className="activity-cardfooter">
                            <div className="date-range">
                              <span>Event Date</span>
                              {activity.startDate === activity.endDate ? (
                                <p>{moment(activity.startDate).format("ll")}</p>
                              ) : (
                                <p>
                                  {" "}
                                  {moment(activity.startDate).format(
                                    "ll"
                                  )} - {moment(activity.endDate).format("ll")}
                                </p>
                              )}
                            </div>
                            <Dropdown className="card-action" placement="left">
                              <Dropdown.Toggle>
                                <ActionIcon size="16" color="#323232" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="card-action-menu">
                                <Dropdown.Item
                                  className="menu-item"
                                  onClick={() => handleEdit(activity)}
                                >
                                  Edit Event
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="menu-item"
                                  onClick={() => handleDelete(activity?._id)}
                                >
                                  Delete Event
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Card.Footer>
                        </Card>
                      </Col>
                    );
                  })
              )}
            </Row>
          </Card.Body>
        </Card>
      </div>

      {/* ACTIVITY MODAL */}
      <Modal
        centered
        size="md"
        show={addEventModal}
        onHide={() => setAddEventModal(!addEventModal)}
      >
        <form className="add-user-form" onSubmit={formik.handleSubmit}>
          <Modal.Header className="add-user-modal-header">
            <h5>{edit ? "Edit" : "Add"} Event</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    Event Title <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.errors.name && formik.touched.name
                        ? "validation-border"
                        : ""
                    }`}
                    name="name"
                    id="name"
                    placeholder="Web & Video Marketing"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="description">
                    Event Description <span>*</span>
                  </label>
                  <textarea
                    rows={4}
                    className={`textarea-control ${
                      formik.errors.discription && formik.touched.discription
                        ? "validation-border"
                        : ""
                    }`}
                    name="discription"
                    id="discription"
                    placeholder="Short description about event"
                    value={formik.values.discription}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="startDate">
                    Start Date <span>*</span>
                  </label>
                  <DatePicker
                    selected={formik.values.startDate}
                    value={formik.values.startDate}
                    onChange={(e) => {
                      formik.setFieldValue("startDate", e);
                    }}
                    className={`datepicker-control ${
                      formik.errors.startDate && formik.touched.startDate
                        ? "validation-border"
                        : ""
                    }`}
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="endDate">
                    End Date <span>*</span>
                  </label>
                  <DatePicker
                    selected={formik.values.endDate}
                    value={formik.values.endDate}
                    onChange={(e) => {
                      formik.setFieldValue("endDate", e);
                    }}
                    className={`datepicker-control ${
                      formik.errors.endDate && formik.touched.endDate
                        ? "validation-border"
                        : ""
                    }`}
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="startDate">
                    Start Time <span>*</span>
                  </label>
                  {/* <TimePicker
                    format={"hh:mm a"}
                    defaultValue={formik.values.startTime}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "startTime",
                        e
                      );
                    }}
                    className={`custom-datepicker-control ${formik.errors.startTime && formik.touched.startTime
                      ? "validation-border"
                      : ""
                      }`}
                    placeholder="Start Time"
                    showSecond={false}
                    use12Hours
                  /> */}
                  <TimePicker
                    className={`custom-datepicker-control form-control ${
                      formik.errors.startTime && formik.touched.startTime
                        ? "validation-border"
                        : ""
                    }`}
                    onChange={(e) => {
                      formik.setFieldValue("startTime", e);
                    }}
                    value={formik.values.startTime}
                    placeholder="Start Time"
                  />
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="startDate">
                    End Time <span>*</span>
                  </label>
                  {/* <TimePicker
                    format={"hh:mm a"}
                    value={formik.values.endTime}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "endTime",
                        e
                      );
                    }}
                    className={`custom-datepicker-control ${formik.errors.endTime && formik.touched.endTime
                      ? "validation-border"
                      : ""
                      }`}
                    use12Hours
                    showSecond={false}
                    placeholder="End Time"
                  /> */}
                  <TimePicker
                    className={`custom-datepicker-control form-control ${
                      formik.errors.endTime && formik.touched.endTime
                        ? "validation-border"
                        : ""
                    }`}
                    onChange={(e) => {
                      formik.setFieldValue("endTime", e);
                    }}
                    value={formik.values.endTime}
                    placeholder="End Time"
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="type">
                    Type <span>*</span>
                  </label>
                  <Select
                    options={typeOption}
                    id="type"
                    name="type"
                    value={typeOption.find(
                      (option) => option.value === formik.values.type
                    )}
                    onChange={(e) => {
                      console.log("e: ", e);
                      formik.setFieldValue("type", e.value);
                    }}
                    className={`select-control ${
                      formik.errors.type && formik.touched.type
                        ? "validation-border"
                        : ""
                    }`}
                    classNamePrefix="custom"
                    placeholder="Event Type"
                  />
                </div>
              </Col>
              {formik?.values?.type === "ONLINE" ? (
                <Col lg="12" sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="description">
                      Meeting Link <span>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.link && formik.touched.link
                          ? "validation-border"
                          : ""
                      }`}
                      name="link"
                      id="link"
                      placeholder="Meeting Link"
                      value={formik.values.link}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Col>
              ) : (
                <>
                  <Col lg="12" sm="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="description">
                        Address Line <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`datepicker-control ${
                          formik.errors.addressLine1 &&
                          formik.touched.addressLine1
                            ? "validation-border"
                            : ""
                        }`}
                        name="addressLine1"
                        id="adddressLine1"
                        placeholder="Address Line "
                        value={formik.values.addressLine1}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </Col>
                  <Col lg="6" sm="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="description">
                        City <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.city && formik.touched.city
                            ? "validation-border"
                            : ""
                        }`}
                        name="city"
                        id="city"
                        placeholder="City"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </Col>
                  <Col lg="6" sm="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="description">
                        State <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.state && formik.touched.state
                            ? "validation-border"
                            : ""
                        }`}
                        name="state"
                        id="state"
                        placeholder="State"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </Col>
                  <Col lg="6" sm="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="description">
                        Country <span>*</span>
                      </label>
                      <input
                        type="country"
                        className={`form-control ${
                          formik.errors.country && formik.touched.country
                            ? "validation-border"
                            : ""
                        }`}
                        name="country"
                        id="country"
                        placeholder="Country"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </Col>
                  <Col lg="6" sm="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="description">
                        Zip Code <span>*</span>
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          formik.errors.zipCode && formik.touched.zipCode
                            ? "validation-border"
                            : ""
                        }`}
                        name="zipCode"
                        id="zipCode"
                        placeholder="Zip code"
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setAddEventModal(!addEventModal)}
            >
              Cancel
            </Button>
            <Button className="proceed-btn" type="submit" disabled={loading}>
              {" "}
              {loading ? <Spinner /> : edit ? "Edit Event" : "Add Event"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </section>
  );
};

export default Events;
