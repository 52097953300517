/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

/* REDUX ACTIONS */
import {
  deleteContactUser,
  editContactUser,
  getContactUser,
} from "../../../service/api";

/* ICON IMPORTS */
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-trash";

const Newsletter = () => {
  /* MODAL STATES */
  const [editNewsletterDetails, setEditNewsletterDetails] = useState(false);
  const [deleteNewsletterDetails, setDeleteNewsletterDetails] = useState(false);

  /* API STATES */
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [type, setType] = useState("NEWSLETTER");
  const [newsletterListData, setNewsletterListData] = useState([]);
  const [totalDocs, setTotalDocs] = useState([]);
  const [filterData, setFilterData] = useState([]);

  /* EDIT DETAILS STATE */
  const [editID, setEditID] = useState("");
  const [email, setEmail] = useState("");
  
  /* GET NEWSLETTER EMAILS LIST */
  const getNewsletterData = () => {
    const params = {
      page: page + 1,
      sizePerPage: sizePerPage,
      type: type,
    };
    getContactUser(params).then((res) => {
      setNewsletterListData(res?.data?.docs);
      setFilterData(res?.data?.docs);
      setTotalDocs(res?.data?.totalDocs);
    }).catch((e) => {
      console.log("Error:", e);
    })
  };

  /* EDIT DETAILS */
  const handleEditDetails = () => {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("type", type);
    editContactUser(editID, formData).then(() => {
      setEmail("");
      setEditNewsletterDetails(!editNewsletterDetails);
      toast.success("The email has been updated successfully");
      getNewsletterData();
    }).catch((e) => {
      console.log("Error: ", e);
    }).finally((res) => {
      console.log("Res: ", res);
    })
  };

  /* DELETE DETAILS */
  const handleDeleteDetails = () => {
    deleteContactUser(editID)
      .then(() => {
        getNewsletterData();
        toast.success("The email has been deleted successfully");
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteNewsletterDetails(!deleteNewsletterDetails);
      });
  };

  /* EMAIL SEARCH */
  const handleSearch = (event) => {
    const newData = filterData.filter((row) =>
      row?.email.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setNewsletterListData(newData);
  }; 

  /* GET DETAILS ONLOAD */
  useEffect(() => {
    getNewsletterData();
  }, [page, sizePerPage, type]);

  /* TABLE COLUMNS */
  const columns = [
    {
      name: "Email",
      selector: (row) => (
        <div className="user-detail">
          <h5 style={{ marginLeft: '0px' }}>{row.email}</h5>
        </div>
      ),
    },
    {
      name: "Timestamp",
      selector: (row) => (
        <span className="light-text">
          {moment(row.updatedAt).format("MM/DD/YYYY hh:mm A")}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <EditIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setEditNewsletterDetails(!editNewsletterDetails);
              setEditID(row?._id);
              setEmail(row?.email);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setDeleteNewsletterDetails(!deleteNewsletterDetails);
              setEditID(row?._id);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="users">
      <ToastContainer />

      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="12" sm="12">
              <h1>Newsletter Subscriptions</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your newsletter subscriptions.
              </p>
            </Col>
          </Row>
        </div>

        {/* NEWSLETTER EMAIL LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <p>
                Showing <b>{newsletterListData?.length}</b> subscriptions
              </p>
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                  onChange={handleSearch}
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              <DataTable
                columns={columns}
                data={newsletterListData}
                pagination
              />
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* DELETE MODAL */}
      <Modal
        centered
        backdrop="static"
        show={deleteNewsletterDetails}
        onHide={() => setDeleteNewsletterDetails(!deleteNewsletterDetails)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Confirm Delete</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p>Are you sure you want to delete this email ?</p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setDeleteNewsletterDetails(!deleteNewsletterDetails)}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleDeleteDetails}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* EDIT CONTACT MODAL */}
      <Modal
        centered
        size="md"
        show={editNewsletterDetails}
        onHide={() => setEditNewsletterDetails(!editNewsletterDetails)}
      >
        <form className="add-user-form">
          <Modal.Header className="add-user-modal-header">
            <h5>Edit Details</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setEditNewsletterDetails(!editNewsletterDetails)}
            >
              Cancel
            </Button>
            <Button className="proceed-btn" onClick={handleEditDetails}>
              Save Details
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </section>
  );
};

export default Newsletter;
