/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Modal,
  Form,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import * as yup from 'yup';
import Select from 'react-select';

/* ICON IMPORTS */
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-trash";
import ViewIcon from "@iconscout/react-unicons/icons/uil-eye";

/* API IMPORTS */
import {
  getHomeEvaluationList,
  deleteHomeEvaluationById,
  editHomeEvaluationById,
} from "../../../service/api";
import { useFormik } from "formik";

const propertyTypes = [
  { value: "Single Family Residence", label: "Single Family Residence" },
  { value: "Town House", label: "Town House" },
  { value: "Mobile Home", label: "Mobile Home" },
  { value: "Condo", label: "Condo" },
  { value: "Apartment", label: "Apartment" },
  { value: "Rental", label: "Rental" },
  { value: "Multi-Family", label: "Multi-Family" },
  { value: "Res Land", label: "Res Land" },
  { value: "Land", label: "Land" },
  { value: "Acreage", label: "Acreage" },
  { value: "Commercial", label: "Commercial" },
  { value: "Non-Profit Organization", label: "Non-Profit Organization" },
  { value: "Duplex", label: "Duplex" },
  { value: "Triplex", label: "Triplex" },
  { value: "Fourplex", label: "Fourplex" },
];

const requestingMarketEvaluation = [
  {
    value: "SELLING",
    label: "Thinking of selling this home",
  },
  {
    value: "BUYING",
    label: "Thinking of buying this home",
  },
  { value: "NONE", label: "Just curious" },
];

const currentAgent = [
  {
    value: true,
    label: "Yes, I have an agent",
  },
  {
    value: false,
    label: "No, I don't have an agent",
  },
];

const Users = () => {
  /* INITIALIZE STATE VARIABLE  */
  const [loading, setLoading] = useState(false)
  const [homeEvaluationList, setHomeEvaluationList] = useState([]);
  const [rowID, setRowID] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  /* MODAL STATES */
  const [editDetailsModal, setEditDetailsModal] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  /* GET API FOR HOME EVALUATION LEADS */
  const getHomeEvaluationLeads = () => {
    setLoading(true)
    getHomeEvaluationList().then((res) => {
      setHomeEvaluationList(res?.data);
      console.log(res?.data)
    })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setLoading(false)
      });
  }

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteHomeEvaluationById(deleteId)
      .then((res) => {
        getHomeEvaluationLeads();
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
      });
  };

  /* USE EFFECT TO GET DATA ON LOAD */
  useEffect(() => {
    getHomeEvaluationLeads()
  }, []);

  const columns = [
    {
      name: "Id",
      selector: (row, index) => <span className="light-text">{index + 1}</span>,
      width: "80px",
    },
    {
      name: "Email Address",
      selector: (row) => <span className="light-text">{row.email}</span>,
      sortable: true,
    },

    {
      name: "Property Type",
      selector: (row) => (
        <span className="light-text">
          {row.propertyType ? row.propertyType : "Not Mentioned"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Requesting Marketing Evaluation For",
      selector: (row) => (
        <span className="light-text">{row.whyMarketEvaluation}</span>
      ),
      sortable: true,
    },
    {
      name: "Timestamp",
      selector: (row) => (
        <span className="light-text">
          {moment(row.createdAt).format("MM/DD/YYYY HH:MM A")}
        </span>
      ),
    },
    {
      name: "Actions",
      width: "200px",
      selector: (row) => (
        <div className="table-actions">
          <EditIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setEditDetailsModal(!editDetailsModal);
              setRowID(row);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setDeleteId(row?._id);
              setDeleteModal(!deleteModal);
            }}
          />
          <ViewIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setViewDetailsModal(!viewDetailsModal);
              setRowID(row);
            }}
          />
        </div>
      ),
    },
  ];

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const EditDetailsModal = () => {
    /* VALIDATION SCHEMA */ 
    const validationSchema = yup.object().shape({
        streetAddress: yup.string().required("Address line 1 is required"),
        whyMarketEvaluation: yup.object().required("This field is required"),
        haveAgent: yup.object().required("This field is required"),
        email: yup.string().email().required("Email is required"),
        city: yup.string().required("City is required"),
        state: yup.string().required("State is required"),
        zipCode: yup.string().required("Zip Code is required"),
        bedrooms: yup.string().required("Bedrooms are requried"),
        fullBathroom: yup.string().required("Full Bathrooms are required"),
    });

    console.log(rowID);

    /* FORM VALUES HANDLING */
    const formik = useFormik({
      initialValues: {
        streetAddress: rowID?.propertyAddress?.streetAddress,
        addressLine2: rowID?.propertyAddress?.addressLine2,
        city: rowID?.propertyAddress?.city,
        state: rowID?.propertyAddress?.state,
        zipCode: rowID?.propertyAddress?.zipCode,
        houseSize: rowID?.houseSize,
        lotSize: rowID?.lotSize,
        bedrooms: rowID?.bedrooms,
        fullBathroom: rowID?.fullBathroom,
        partialBathroom: rowID?.partialBathroom,
        basement: rowID?.basement,
        garageSpace: rowID?.garageSpace,
        estimatedValue: rowID?.estimatedValue,
        propertyType: {
          value: rowID?.propertyType,
          label: rowID?.propertyType,
        },
        propertyAge: rowID?.propertyAge,
        propertyCondition: rowID?.propertyCondition,
        notes: rowID?.notes,
        whyMarketEvaluation: {
          value: rowID?.whyMarketEvaluation,
          label: rowID?.whyMarketEvaluation === "SELLING" ? "Thinking of selling this home" : rowID?.whyMarketEvaluation === "BUYING" ? "Thinking of buying this home" : "Just curious",
        },
        email: rowID?.email,
        haveAgent: {
          value: rowID?.haveAgent,
          label: rowID?.haveAgent ? "Yes, I have an agent" : "No, I don't have an agent",
        },
      },
      validationSchema: validationSchema,
      onSubmit: (values, { resetForm }) => {
        setLoading(true);
        console.log(values);
        const formData = new FormData();
        formData.append("streetAddress", values?.streetAddress);
        formData.append(
          "whyMarketEvaluation",
          values?.whyMarketEvaluation?.value
        );
        formData.append("email", values?.email);
        formData.append("haveAgent", values?.haveAgent?.value);
        formData.append("city", values?.city);
        formData.append("state", values?.state);
        formData.append("zipCode", values?.zipCode);
        formData.append("bedrooms", values?.bedrooms);
        formData.append("fullBathroom", values?.fullBathroom);

        if (values?.propertyCondition) {
          formData.append("propertyCondition", values?.propertyCondition);
        }
        if (values?.propertyAge) {
          formData.append("propertyAge", values?.propertyAge);
        }
        if (values?.partialBathroom) {
          formData.append("partialBathroom", values?.partialBathroom);
        }

        if (values?.addressLine2?.trim().length !== 0) {
          formData.append("addressLine2", values?.addressLine2);
        }
        if (values?.houseSize?.trim().length !== 0) {
          formData.append("houseSize", values?.houseSize);
        }
        if (values?.lotSize?.trim().length !== 0) {
          formData.append("lotSize", values?.lotSize);
        }
        if (values?.basement?.trim().length !== 0) {
          formData.append("basement", values?.basement);
        }
        if (values?.garageSpace?.trim().length !== 0) {
          formData.append("garageSpace", values?.garageSpace);
        }
        if (values?.estimatedValue) {
          formData.append("estimatedValue", values?.estimatedValue);
        }
        if (values?.propertyType?.value?.trim().length !== 0) {
          formData.append("propertyType", values?.propertyType?.value);
        }
        if (values?.notes?.trim().length !== 0) {
          formData.append("notes", values?.notes);
        }

        editHomeEvaluationById(rowID?._id, formData)
          .then((res) => {
            if (res?.status) {
              toast.success(res?.message);
              resetForm();
              setEditDetailsModal(!editDetailsModal);
              getHomeEvaluationLeads();
            }
          })
          .catch((e) => {
            console.log("e-->", e);
            toast.error(e?.response?.data?.message);
            setLoading(false);
          })
          .finally((res) => {
            setLoading(false);
          });
      },
    });

    return (
      <>
        {/* VIEW MODAL */}
        <Modal
          centered
          size="lg"
          show={editDetailsModal}
          onHide={() => setEditDetailsModal(!editDetailsModal)}
        >
          <form onSubmit={formik.handleSubmit}>
            <Modal.Header className="view-details-modalheader" closeButton>
              <h5>Form Details</h5>
            </Modal.Header>
            <Modal.Body className="view-details-modalbody">
              <div className="row">
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Street Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="streetAddress"
                      value={formik.values.streetAddress}
                      onChange={formik.handleChange}
                      placeholder="Street Address"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Address Line 2</label>
                    <input
                      type="text"
                      className="form-control"
                      name="addressLine2"
                      value={formik.values.addressLine2}
                      onChange={formik.handleChange}
                      placeholder="Address Line 2"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      placeholder="City"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">State</label>
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      placeholder="State"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Zip Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="zipcode"
                      value={formik.values.zipCode}
                      onChange={formik.handleChange}
                      placeholder="Zip Code"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">House Size (sqft)</label>
                    <input
                      type="text"
                      className="form-control"
                      name="houseSize"
                      value={formik.values.houseSize}
                      onChange={formik.handleChange}
                      placeholder="House Size"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Lot/Site Size (acres)</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lotSize"
                      value={formik.values.lotSize}
                      onChange={formik.handleChange}
                      placeholder="Lot Size"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Basement</label>
                    <div className="d-flex align-items-center justify-content-between">
                      <Form.Check
                        type="radio"
                        className="status-radio"
                        label="None"
                        name="basement"
                        checked={
                          formik.values.basement === "NONE" ? true : false
                        }
                        onChange={(e) => {
                          formik.setFieldValue("basement", "NONE");
                        }}
                        id="basement-none"
                      />
                      <Form.Check
                        type="radio"
                        className="status-radio"
                        label="Finished"
                        name="basement"
                        checked={
                          formik.values.basement === "FINISHED" ? true : false
                        }
                        onChange={(e) => {
                          formik.setFieldValue("basement", "FINISHED");
                        }}
                        id="basement-finished"
                      />
                      <Form.Check
                        type="radio"
                        className="status-radio"
                        label="Unfinished"
                        name="basement"
                        value="UNFINISHED"
                        checked={
                          formik.values.basement === "UNFINISHED" ? true : false
                        }
                        onChange={(e) => {
                          formik.setFieldValue("basement", e.target.value);
                        }}
                        id="basement-unfinished"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Bedrooms
                    </label>
                    <input
                      type="number"
                      className={`form-control ${
                        formik.errors.bedrooms && formik.touched.bedrooms
                          ? "validation-border"
                          : ""
                      }`}
                      name="bedrooms"
                      value={formik.values.bedrooms}
                      onChange={formik.handleChange}
                      placeholder="No. of Bedrooms"
                      min="0"
                      onWheel={numberInputOnWheelPreventChange}
                    />
                    {formik?.values?.bedrooms < 0 && (
                      <small style={{ color: "red" }}>
                        Bedrooms number should be greater than zero
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Full Bathrooms
                    </label>
                    <input
                      type="number"
                      className={`form-control ${
                        formik.errors.fullBathroom &&
                        formik.touched.fullBathroom
                          ? "validation-border"
                          : ""
                      }`}
                      name="fullBathroom"
                      value={formik.values.fullBathroom}
                      onChange={formik.handleChange}
                      placeholder="No. of Full Bathrooms"
                      min="0"
                      onWheel={numberInputOnWheelPreventChange}
                    />
                    {formik?.values?.fullBathroom < 0 && (
                      <small style={{ color: "red" }}>
                        Full Bathrooms number should be greater than zero
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Partial Bathrooms</label>
                    <input
                      type="number"
                      className="form-control"
                      name="partialBathroom"
                      value={formik.values.partialBathroom}
                      onChange={formik.handleChange}
                      placeholder="No. of Partial Bathrooms"
                      min="0"
                      onWheel={numberInputOnWheelPreventChange}
                    />
                    {formik?.values?.partialBathroom < 0 && (
                      <small style={{ color: "red" }}>
                        Partial Bathrooms number should be greater than zero
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Estimated Value</label>
                    <input
                      type="text"
                      className="form-control"
                      name="estimatedValue"
                      value={formik.values.estimatedValue}
                      onChange={formik.handleChange}
                      placeholder="Estimated Value"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Garage Spaces</label>
                    <input
                      type="text"
                      className="form-control"
                      name="garageSpaces"
                      value={formik.values.garageSpace}
                      onChange={formik.handleChange}
                      placeholder="Garage Spaces"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group" htmlFor="property-type">
                    <label className="form-label">Type of Property</label>
                    <Select
                      options={propertyTypes}
                      className="react-select"
                      classNamePrefix="custom-select"
                      placeholder="Property Type"
                      id="property-type"
                      value={
                        formik.values.propertyType.value !== ""
                          ? formik.values.propertyType
                          : null
                      }
                      onChange={(e) => {
                        formik.setFieldValue("propertyType", e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Age of Property</label>
                    <input
                      type="number"
                      className="form-control"
                      name="propertyAge"
                      value={formik.values.propertyAge}
                      onChange={formik.handleChange}
                      placeholder="Age of Property"
                      min="0"
                      onWheel={numberInputOnWheelPreventChange}
                    />
                    {formik?.values?.propertyAge < 0 && (
                      <small style={{ color: "red" }}>
                        Property age number should be greater than zero
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Condition of Property
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="propertyCondition"
                      value={formik.values.propertyCondition}
                      onChange={formik.handleChange}
                      placeholder="Please enter a number from 1 to 10"
                      min="1"
                      max="10"
                      onWheel={numberInputOnWheelPreventChange}
                    />
                    {formik?.values?.propertyCondition < 1 &&
                      formik?.values?.propertyCondition !== "" && (
                        <small style={{ color: "red" }}>
                          Property Condition should be greater than one.
                        </small>
                      )}
                    {formik?.values?.propertyCondition > 10 &&
                      formik?.values?.propertyCondition !== "" && (
                        <small style={{ color: "red" }}>
                          Property Condition should be less than ten
                        </small>
                      )}
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label
                      className="form-label"
                      htmlFor="requesting-market-evaluation"
                    >
                      Why requesting a Market Evaluation
                    </label>
                    <Select
                      options={requestingMarketEvaluation}
                      className={`react-select ${
                        formik.errors.whyMarketEvaluation &&
                        formik.touched.whyMarketEvaluation
                          ? "validation-border"
                          : ""
                      }`}
                      id="requesting-market-evaluation"
                      classNamePrefix="custom-select"
                      placeholder="Why are you requesting marker evaluation"
                      value={formik.values.whyMarketEvaluation}
                      onChange={(e) => {
                        formik.setFieldValue("whyMarketEvaluation", e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="currently-working">
                      Are you currently working with a Real Estate Agent?
                    </label>
                    <Select
                      options={currentAgent}
                      className={`react-select ${
                        formik.errors.haveAgent && formik.touched.haveAgent
                          ? "validation-border"
                          : ""
                      }`}
                      id="currently-working"
                      classNamePrefix="custom-select"
                      placeholder="Are you currently working with a Real Estate Agent"
                      value={formik.values.haveAgent}
                      onChange={(e) => {
                        formik.setFieldValue("haveAgent", e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="Email Address"
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Notes</label>
                    <textarea
                      rows="4"
                      className="textarea-control"
                      name="notes"
                      value={formik.values.notes}
                      onChange={formik.handleChange}
                      placeholder="Notes"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="add-user-modal-footer">
              <Button
                className="cancel-btn"
                onClick={() => setEditDetailsModal(!editDetailsModal)}
              >
                Cancel
              </Button>
              <Button type="submit" className="proceed-btn">
                Save details
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  };

  return (
    <section className="users">
      <ToastContainer />
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Home Evaluation Leads</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through home evaluation leads.
              </p>
            </Col>
          </Row>
        </div>

        {/* USERS LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <p>
                Showing <b>{homeEvaluationList?.length}</b> queries
              </p>
            </Card.Header>
            <Card.Body className="users-list-body">
              {loading ? (
                <Skeleton className="users-table-loading" />
              ) : (
                <DataTable
                  columns={columns}
                  data={homeEvaluationList}
                  pagination
                />
              )}
            </Card.Body>
          </Card>
        </div>

        <EditDetailsModal />

        {/* DELETE MODAL */}
        <Modal
          centered
          backdrop="static"
          show={deleteModal}
          onHide={() => setDeleteModal(!deleteModal)}
        >
          <Modal.Header className="delete-user-modal-header">
            <h5>Confirm Delete</h5>
          </Modal.Header>
          <Modal.Body className="delete-user-modal-body">
            <p>Are you sure you want to delete these form details ?</p>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setDeleteModal(!deleteModal)}
            >
              Cancel
            </Button>
            <Button className="proceed-btn" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* VIEW MODAL */}
        <Modal
          centered
          size="lg"
          show={viewDetailsModal}
          onHide={() => setViewDetailsModal(!viewDetailsModal)}
        >
          <Modal.Header className="view-details-modalheader" closeButton>
            <h5>Form Details</h5>
          </Modal.Header>
          <Modal.Body className="view-details-modalbody">
            <div className="row">
              <div className="col-xl-6 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Street Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="streetAddress"
                    disabled
                    value={rowID?.propertyAddress?.streetAddress}
                    placeholder="Street Address"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Address Line 2</label>
                  <input
                    type="text"
                    className="form-control"
                    name="streetAddress"
                    disabled
                    value={
                      rowID?.propertyAddress?.addressLine2 === ""
                        ? "-"
                        : rowID?.propertyAddress?.addressLine2
                    }
                    placeholder="Address Line 2"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    disabled
                    value={rowID?.propertyAddress?.city}
                    placeholder="City"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    disabled
                    value={rowID?.propertyAddress?.state}
                    placeholder="State"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Zip Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="zipcode"
                    disabled
                    value={rowID?.propertyAddress?.zipCode}
                    placeholder="Zip Code"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">House Size (sqft)</label>
                  <input
                    type="text"
                    className="form-control"
                    name="house-size"
                    disabled
                    value={rowID?.houseSize}
                    placeholder="House Size"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Lot/Site Size (acres)</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lot-size"
                    disabled
                    value={rowID?.lotSize}
                    placeholder="Lot Size"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Basement</label>
                  <input
                    type="text"
                    className="form-control"
                    name="basement"
                    disabled
                    value={rowID?.basement}
                    placeholder="Basemment"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Bedrooms</label>
                  <input
                    type="text"
                    className="form-control"
                    name="bedrooms"
                    disabled
                    value={rowID?.bedrooms}
                    placeholder="Bedrooms"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Full Bathrooms</label>
                  <input
                    type="text"
                    className="form-control"
                    name="full-bathrooms"
                    disabled
                    value={rowID?.fullBathroom}
                    placeholder="Full Bathrooms"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Partial Bathrooms</label>
                  <input
                    type="text"
                    className="form-control"
                    name="partial-bathrooms"
                    id="partial-bathrooms"
                    disabled
                    value={rowID?.partialBathroom}
                    placeholder="Partial Bathrooms"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Estimated Value</label>
                  <input
                    type="text"
                    className="form-control"
                    name="estimatedValue"
                    disabled
                    value={rowID?.estimatedValue}
                    placeholder="Estimated Value"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Garage Spaces</label>
                  <input
                    type="text"
                    className="form-control"
                    name="garageSpaces"
                    disabled
                    value={rowID?.garageSpace}
                    placeholder="Garage Spaces"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Property Type</label>
                  <input
                    type="text"
                    className="form-control"
                    name="propertyType"
                    disabled
                    value={rowID?.propertyType}
                    placeholder="Property Type"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Property Age</label>
                  <input
                    type="text"
                    className="form-control"
                    name="propertyAge"
                    disabled
                    value={rowID?.propertyAge}
                    placeholder="Property Age"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Property Condition</label>
                  <input
                    type="text"
                    className="form-control"
                    name="propertyCondition"
                    disabled
                    value={rowID?.propertyCondition}
                    placeholder="Property Condition"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">
                    Why Requesting Market Evaluation
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="requestingMarketEvaluation"
                    disabled
                    value={rowID?.whyMarketEvaluation}
                    placeholder="Market Evaluation"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="form-group">
                  <label className="form-label">
                    Are you currently working with a Real Estate Agent?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="currentlyWorking"
                    disabled
                    value={
                      rowID?.haveAgent
                        ? "Yes, I have an agent"
                        : "No, I don't have an agent"
                    }
                    placeholder="Currently working with Real Estate Agent"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    disabled
                    value={rowID?.email}
                    placeholder="Email Address"
                  />
                </div>
              </div>
              <div className="col-xl-12 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Notes</label>
                  <textarea
                    rows="4"
                    className="textarea-control"
                    name="notes"
                    disabled
                    value={rowID?.notes}
                    placeholder="Notes"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </section>
  );
};

export default Users;
