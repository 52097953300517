/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { Row, Col, Tab, Card, Nav, Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { useFormik } from "formik";
import * as yup from 'yup';

/* API IMPORTS */
import {
  getNewHomeEvaluationStatusList,
  getNewHomeEvaluationList,
  deleteNewHomeEvaluationById,
  editNewHomeEvaluationById,
} from "../../../service/api";

/* ICON IMPORTS */
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-trash";
import ViewIcon from "@iconscout/react-unicons/icons/uil-eye";
import { useEffect, useState } from "react";

const LeadsDashboard = () => {
  /* INITIALIZE STATE VARIABLE  */
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [currentTab, setCurrentTab] = useState("new-leads")
  const [homeEvaluationList, setHomeEvaluationList] = useState([]);
  const [newHomeEvaluationList, setNewHomeEvaluationList] = useState([])
  const [completeHomeEvaluationList, setCompleteHomeEvaluationList] = useState([])
  const [partialEvaluationList, setPartialHomeEvaluationList] = useState([])
  const [statusList, setStatusList] = useState([]);
  const [rowID, setRowID] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  /* MODAL STATES */
  const [editDetailsModal, setEditDetailsModal] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  /* GET API FOR HOME EVALUATION LEADS */
  const getNewHomeEvaluationLeads = () => {
    setLoading(true)
    getNewHomeEvaluationList(status).then((res) => {
      setHomeEvaluationList(res?.data);
      let partialList = []
      let completeList = []
      let newList = []
      res?.data?.forEach((element) => {
        if (element?.firstName !== undefined && element?.lastName !== undefined && element?.phone !== undefined && element?.email !== undefined) {
          completeList.push(element)
        } else {
          partialList.push(element)
        }
        if (element?.status === 1) {
          newList.push(element)
        }
      })
      setCompleteHomeEvaluationList(completeList)
      setPartialHomeEvaluationList(partialList)
      setNewHomeEvaluationList(newList)
      console.log(res?.data)
    })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setLoading(false)
      });

  }
  /* GET API FOR HOME EVALUATION STATUS LIST */
  const getNewHomeEvaluationStatusID = () => {
    getNewHomeEvaluationStatusList().then((res) => {
      let tempArray = []
      res?.data?.forEach((element, index) => {
        tempArray.push({
          value: element?.id,
          label: element?.value
        })
      })
      setStatusList(tempArray);
      console.log(res?.data)
    })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setLoading(false)
      });
  }

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteNewHomeEvaluationById(deleteId)
      .then((res) => {
        getNewHomeEvaluationLeads();
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
      });
  };

  /* DATATABLE COLUMN LIST */
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span className="light-text">{index + 1}</span>,
      width: "80px",
    },
    {
      name: "Address",
      selector: (row) => (
        <span className="light-text" style={{ overflowWrap: "break-word" }}>
          {row?.streetAddress +
            ", " +
            row?.city +
            ", " +
            row?.state +
            " " +
            row?.pinCode}
        </span>
      ),
      width: "250px",
    },
    {
      name: "Name",
      width: "160px",
      selector: (row) => (
        <span className="light-text">
          {row?.firstName && row?.lastName
            ? row?.firstName + " " + row?.lastName
            : "-"}
        </span>
      ),
      sortable: true,
    },

    {
      name: "Email Address",
      selector: (row) => (
        <span className="light-text">{row?.email ? row?.email : "-"}</span>
      ),
      sortable: true,
    },
    {
      name: "Phone Number",
      width: "140px",
      selector: (row) => (
        <span className="light-text">{row?.phone ? row?.phone : "-"}</span>
      ),
      sortable: true,
    },
    {
      name: "Timestamp",
      width: "170px",
      selector: (row) => (
        <span className="light-text ">
          {new Date(row.createdAt).toLocaleString('en-US', { timeZone: 'America/Los_Angeles', dateStyle: 'medium', timeStyle: 'short', },)}
        </span>
      ),
    },
    {
      name: "Status"
      ,
      width: "140px",
      selector: (row) => (
        <span className="light-text">
          <div className="filters">
            {statusList.map(function (item, id) {
              if (parseInt(item?.value) === parseInt(row?.status)) {
                if (item?.label === "No Status") {
                  return (
                    <div key={id} className="filter-badge-secondary">
                      <p>{item?.label}</p>
                    </div>
                  );
                } else if (item?.label === "Closed") {
                  return (
                    <div key={id} className="filter-badge-success">
                      <p>{item?.label}</p>
                    </div>
                  );
                } else if (item?.label === "Cold") {
                  return (
                    <div key={id} className="filter-badge-info">
                      <p>{item?.label}</p>
                    </div>
                  );
                } else if (item?.label === "Hot") {
                  return (
                    <div key={id} className="filter-badge-warning">
                      <p>{item?.label}</p>
                    </div>
                  );
                } else if (item?.label === "Dead") {
                  return (
                    <div key={id} className="filter-badge-error">
                      <p>{item?.label}</p>
                    </div>
                  );
                } else {
                  return (
                    <div key={id} className="filter-badge-primary">
                      <p>{item?.label}</p>
                    </div>
                  );
                }
              }
            })}
          </div>
        </span>
      ),
    },
    {
      name: "Actions",
      width: "120px",
      selector: (row) => (
        <div className="table-actions">
          <EditIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setEditDetailsModal(!editDetailsModal);
              setRowID(row);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setDeleteId(row?._id);
              setDeleteModal(!deleteModal);
            }}
          />
          {/* <ViewIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setViewDetailsModal(!viewDetailsModal);
              setRowID(row);
            }}
          /> */}
        </div>
      ),
    },
  ];

  /* HANDLE EDIT MODAL */
  const EditDetailsModal = () => {
    /* VALIDATION SCHEMA */
    const validationSchema = yup.object().shape({
      streetAddress: yup.string(),
      city: yup.string(),
      state: yup.string(),
      country: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
      phone: yup.string(),
    });

    console.log(rowID);

    /* FORM VALUES HANDLING */
    const formik = useFormik({
      initialValues: {
        streetAddress: rowID?.streetAddress,
        city: rowID?.city,
        state: rowID?.state,
        pinCode: rowID?.pinCode,
        country: rowID?.country,
        email: rowID?.email,
        phone: rowID?.phone,
        status: statusList?.filter((element) => {
          return parseInt(element?.value) === parseInt(rowID?.status)
        })?.[0],
        firstName: rowID?.firstName,
        lastName: rowID?.lastName,
        addedForAgent: rowID?.addedForAgent
      },
      validationSchema: validationSchema,
      onSubmit: (values, { resetForm }) => {
        setLoading(true);
        console.log(values);
        const formData = new FormData();
        formData.append("streetAddress", values?.streetAddress);
        formData.append("city", values?.city);
        formData.append("state", values?.state);
        formData.append("pinCode", values?.pinCode);
        if (values?.email) {
          formData.append("email", values?.email);
        }
        if (values?.firstName) {
          formData.append("firstName", values?.firstName);
        }
        if (values?.lastName) {
          formData.append("lastName", values?.lastName);
        }
        if (values?.status?.value) {
          formData.append("status", values?.status?.value);
        }

        if (values?.addedForAgent) {
          formData.append("addedForAgent", values?.addedForAgent);
        }

        editNewHomeEvaluationById(rowID?._id, formData)
          .then((res) => {
            if (res?.status) {
              toast.success(res?.message);
              resetForm();
              setEditDetailsModal(!editDetailsModal);
              getNewHomeEvaluationLeads();
            }
          })
          .catch((e) => {
            console.log("e-->", e);
            toast.error(e?.response?.data?.message);
            setLoading(false);
          })
          .finally((res) => {
            setLoading(false);
          });
      },
    });

    return (
      <>
        {/* VIEW MODAL */}
        <Modal
          centered
          size="lg"
          show={editDetailsModal}
          onHide={() => setEditDetailsModal(!editDetailsModal)}
        >
          <form onSubmit={formik.handleSubmit}>
            <Modal.Header className="view-details-modalheader" closeButton>
              <h5>Form Details</h5>
            </Modal.Header>
            <Modal.Body className="view-details-modalbody">
              <div className="row">
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Street Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="streetAddress"
                      value={formik.values.streetAddress}
                      onChange={formik.handleChange}
                      placeholder="Street Address"
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      placeholder="City"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">State</label>
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      placeholder="State"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Pincode</label>
                    <input
                      type="text"
                      className="form-control"
                      name="pinCode"
                      value={formik.values.pinCode}
                      onChange={formik.handleChange}
                      placeholder="Pin Code"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Country</label>
                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      placeholder="Country"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="Email Address"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <div className="form-group" htmlFor="property-type">
                    <label className="form-label">Select Status</label>
                    <Select
                      options={statusList}
                      className="react-select"
                      classNamePrefix="custom-select"
                      placeholder="Select Status"
                      id="status"
                      value={
                        formik?.values?.status?.value !== ""
                          ? formik?.values?.status
                          : null
                      }
                      onChange={(e) => {
                        formik.setFieldValue("status", e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="add-user-modal-footer">
              <Button
                className="cancel-btn"
                onClick={() => setEditDetailsModal(!editDetailsModal)}
              >
                Cancel
              </Button>
              <Button type="submit" className="proceed-btn">
                Save details
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  };

  /* HANDLE DELETE MODAL */
  const DeleteModal = () => {

    return <Modal
      centered
      backdrop="static"
      show={deleteModal}
      onHide={() => setDeleteModal(!deleteModal)}
    >
      <Modal.Header className="delete-user-modal-header">
        <h5>Confirm Delete</h5>
      </Modal.Header>
      <Modal.Body className="delete-user-modal-body">
        <p>Are you sure you want to delete these form details ?</p>
      </Modal.Body>
      <Modal.Footer className="add-user-modal-footer">
        <Button
          className="cancel-btn"
          onClick={() => setDeleteModal(!deleteModal)}
        >
          Cancel
        </Button>
        <Button className="proceed-btn" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  }

  /* USE-EFFECT TO GET DATA */
  useEffect(() => {
    getNewHomeEvaluationStatusID()
    getNewHomeEvaluationLeads()
  }, [status])

  return (
    <section className="leads-dashboard">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Leads</h1>
              <p style={{ fontSize: "15px" }}>
                Connect with the captured leads that empowers your sales success
              </p>
            </Col>
          </Row>
        </div>

        <Tab.Container defaultActiveKey="new-leads">
          <Row>
            <Col xl="12" sm="12">
              <Card className="settings-tabs mb-5">
                <Card.Header className="settings-tabs-cardheader">
                  <Nav variant="pills" className="flex-row justify-content-between">
                    <div className="d-flex align-items-center">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="new-leads"
                          onClick={() => {
                            setCurrentTab("new-leads");
                            setStatus(0);
                          }}
                        >
                          New Leads
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="total-leads"
                          onClick={() => {
                            setCurrentTab("total-leads");
                            setStatus(0);
                          }}
                        >
                          Total Leads
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="partial-leads"
                          onClick={() => {
                            setCurrentTab("partial-leads");
                            setStatus(0);
                          }}
                        >
                          Partial Leads
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="complete-leads"
                          onClick={() => {
                            setCurrentTab("complete-leads");
                            setStatus(0);
                          }}
                        >
                          Complete Leads
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                    {currentTab !== "new-leads" ? (
                      <div className="form-group p-0 m-0 d-flex status-dropdown-div">
                        <select
                          onChange={(e) => {
                            setStatus(e?.target?.value);
                          }}
                          className="form-control  status-dropdown"
                          defaultValue={status}
                          value={status}
                          name="status-selector"
                          id="status-selector"
                          placeholder="Select Status"
                        >
                          {/* <option value={"none"} disabled selected>
                            Select Status
                          </option> */}
                          <option value={0}>Select Status</option>
                          {statusList?.length > 0
                            ? statusList?.map((element, id) => {
                              return (
                                <option key={id} value={element?.value}>
                                  {element?.label}
                                </option>
                              );
                            })
                            : null}
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                  </Nav>

                  {/* <div className="col-xl-4 col-sm-12">
                  <div className="form-group" htmlFor="property-type">
                    <label className="form-label">Select Status</label>
                    <Select
                      options={statusList}
                      className="react-select"
                      classNamePrefix="custom-select"
                      placeholder="Select Status"
                      id="filterStatus"
                      // value={
                      //   formik?.values?.status?.value !== ""
                      //     ? formik?.values?.status
                      //     : null
                      // }
                      // onChange={(e) => {
                      //   formik.setFieldValue("status", e);
                      // }}
                    />
                  </div>
                </div>
               */}
                </Card.Header>
                <Card.Body className="settings-tabs-cardbody">
                  <Tab.Content>
                    <Tab.Pane eventKey="new-leads">
                      <DataTable
                        columns={columns}
                        data={newHomeEvaluationList}
                        pagination
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="total-leads">
                      <DataTable
                        columns={columns}
                        data={homeEvaluationList}
                        pagination
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="partial-leads">
                      <DataTable
                        columns={columns}
                        data={partialEvaluationList}
                        pagination
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="complete-leads">
                      <DataTable
                        columns={columns}
                        data={completeHomeEvaluationList}
                        pagination
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Tab.Container>

        <EditDetailsModal />
        <DeleteModal />
      </div>
    </section>
  );
};

export default LeadsDashboard;